import toast from "toasted-notes";
import "toasted-notes/src/styles.css"; // optional styles
import {
  CREATE_ORDER,
  CREATE_ORDER_FAIL,
  CREATE_ORDER_SUCCESS,
  GET_ORDERS,
  GET_ORDERS_FAIL,
  GET_ORDERS_STATUS,
  GET_ORDERS_STATUS_FAIL,
  GET_ORDERS_STATUS_SUCCESS,
  GET_ORDERS_SUCCESS,
  ORDER_OVERVIEW_FAILED,
  UPDATE_ORDER_FAIL,
  UPDATE_ORDER_SUCCESS,
} from "../../actions/orders/types";

const initialState = {
  orders: [],
  loading: true,
  error: false,
};
export const order = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ORDERS:
      return {
        ...state,
        loading: true,
      };
    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        orders: payload.orders,
        totalCount: payload.totalCount,
        loading: false,
      };
    case GET_ORDERS_FAIL:
      return {
        ...state,
        orders: [],
        error: true,
        loading: false,
      };
    case CREATE_ORDER:
      return {
        ...state,
        loading: true,
      };
    case CREATE_ORDER_SUCCESS:
      toast.notify("Order created successfully!", {
        position: "bottom-right",
        duration: 1300,
      });
      return {
        ...state,
        loading: false,
      };

    case CREATE_ORDER_FAIL:
      toast.notify("Error! Faild to create new order!", {
        position: "bottom-right",
        duration: 3000,
      });
      return {
        ...state,
        loading: false,
      };
    case GET_ORDERS_STATUS:
      return {
        ...state,
        loading: true,
      };
    case GET_ORDERS_STATUS_SUCCESS:
      return {
        ...state,

        orders: state.orders.map((item) =>
          item._id === payload._id ? { ...item, status: payload.status } : item
        ),

        loading: false,
      };
    case GET_ORDERS_STATUS_FAIL:
      return {
        ...state,
        orders: [],
        error: true,
        loading: false,
      };

    case UPDATE_ORDER_SUCCESS:
      toast.notify("Order Updated Successfully!", {
        position: "bottom-right",
        duration: 3000,
      });
      return {
        ...state,
        error: false,
        loading: false,
      };
    case UPDATE_ORDER_FAIL:
      toast.notify(`Order Update Failed!\n${payload.data.error}`, {
        position: "bottom-right",
        duration: 3000,
      });
      return {
        ...state,
        error: true,
        loading: false,
      };

    case ORDER_OVERVIEW_FAILED:
      toast.notify(`Can't get orders overview!`, {
        position: "bottom-right",
        duration: 3000,
      });
      break;
    default: {
      return state;
    }
  }
};
