import toaster from "toasted-notes";
import "toasted-notes/src/styles.css";
import {
  APPROVE_MERCHANT_POST_REQUEST,
  APPROVE_MERCHANT_POST_REQUEST_FAIL,
  APPROVE_MERCHANT_POST_REQUEST_SUCCESS,
  DENY_MERCHANTS_POST_REQUEST,
  DENY_MERCHANTS_POST_REQUEST_FAIL,
  DENY_MERCHANTS_POST_REQUEST_SUCCESS,
  GET_DATA_FAIL,
  GET_MERCHANTS_FOR_POST_APPROVAL,
  GET_MERCHANTS_FOR_POST_APPROVAL_FAIL,
  GET_MERCHANTS_FOR_POST_APPROVAL_SUCCESS,
  GET_SHOPS,
  GET_SHOPS_SUCCESS,
  GET_SHOP_DETAILS,
  GET_SHOP_DETAILS_SUCCESS,
  GET_SHOP_DETAIL_FAIL,
  GET_SHOP_INITIAL_PRODUCTS_SUCCESS,
  GET_SHOP_OVERVIEW,
  GET_SHOP_OVERVIEW_FAIL,
  GET_SHOP_OVERVIEW_SUCCESS,
  GET_SHOP_PRODUCTS,
  GET_SHOP_PRODUCTS_FAIL,
  GET_SHOP_PRODUCTS_SUCCESS,
} from "../../actions/ecommerce/types";

const initialState = {
  data: [],
  params: null,
  allData: [],
  totalPages: 0,
  filteredData: [],
  totalRecords: 0,
  loading: false,
  overViewLoading: false,
  sortIndex: [],
  next: "",
  previous: "",
  shopOverview: {},
  shopDetails: {},
  merchantsForPostApprovalLoading: false,
  merchantsForPostApproval: undefined,
  merchantsForPostApprovalError: undefined,
  merchantRequestLoading: false,
};

const shop = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_SHOPS:
      return {
        ...state,
        loading: true,
      };
    case GET_SHOPS_SUCCESS:
      return {
        ...state,
        shops: payload,
        loading: false,
      };
    case GET_DATA_FAIL:
      console.log("error occured here");
      return { ...state, loading: false, failed: true };

    case GET_SHOP_PRODUCTS:
      return {
        ...state,
        loading: true,
      };
    case GET_SHOP_INITIAL_PRODUCTS_SUCCESS:
      return {
        ...state,
        data: action.data,
        totalPages: action.totalPages,
        allData: action.data.results,
        loading: false,
      };
    case GET_SHOP_PRODUCTS_SUCCESS:
      return {
        data: action.data,
        loading: false,
        allData: action.data.results,
        totalPages: action.totalPages,
      };
    case GET_SHOP_PRODUCTS_FAIL:
      return { ...state, loading: false, failed: true };

    case GET_SHOP_DETAILS:
      return {
        loading: true,
      };
    case GET_SHOP_DETAILS_SUCCESS:
      return { ...state, shopDetails: payload, loading: false };
    case GET_SHOP_DETAIL_FAIL:
      return { ...state, loading: false, failed: true };

    case GET_SHOP_OVERVIEW:
      return {
        overViewLoading: true,
      };
    case GET_SHOP_OVERVIEW_SUCCESS:
      return { ...state, shopOverview: payload, overViewLoading: false };
    case GET_SHOP_OVERVIEW_FAIL:
      return { ...state, overViewLoading: false, failed: true };

    case GET_MERCHANTS_FOR_POST_APPROVAL:
      return {
        ...state,
        merchantsForPostApprovalLoading: true,
      };
    case GET_MERCHANTS_FOR_POST_APPROVAL_SUCCESS:
      return {
        ...state,
        merchantsForPostApprovalLoading: false,
        merchantsForPostApproval: payload,
      };
    case GET_MERCHANTS_FOR_POST_APPROVAL_FAIL:
      return {
        ...state,
        merchantsForPostApprovalLoading: false,
        merchantsForPostApprovalError: payload,
      };
    case APPROVE_MERCHANT_POST_REQUEST || DENY_MERCHANTS_POST_REQUEST:
      return {
        ...state,
        merchantRequestLoading: true,
      };
    case APPROVE_MERCHANT_POST_REQUEST_SUCCESS:
      toaster.notify("Merchant approved!", {
        position: "top-right",
        duration: null,
      });
      return {
        ...state,
        merchantRequestLoading: false,
        merchantsForPostApproval:
          state.merchantsForPostApproval.length &&
          state.merchantsForPostApproval.map((item) =>
            item._id === payload._id ? payload : item
          ),
      };
    case APPROVE_MERCHANT_POST_REQUEST_FAIL || DENY_MERCHANTS_POST_REQUEST_FAIL:
      return {
        ...state,
        merchantRequestLoading: false,
        merchantsForPostApprovalError: payload,
      };
    case DENY_MERCHANTS_POST_REQUEST_SUCCESS:
      toaster.notify("Merchant denied!", {
        position: "top-right",
        duration: null,
      });
      return {
        ...state,
        merchantRequestLoading: false,
        merchantsForPostApproval:
          state.merchantsForPostApproval.length &&
          state.merchantsForPostApproval.map((item) =>
            item._id === payload._id ? payload : item
          ),
      };
    default: {
      return state;
    }
  }
};

export default shop;
