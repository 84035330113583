import mock from "../mock";

export const searchResult = [
  {
    groupTitle: "Pages",
    searchLimit: 4,
    data: [
      {
        id: 1,
        target: "dashboard",
        title: "Dashboard",
        link: "/",
        icon: "Home",
      },

      {
        id: 4,
        target: "E-commerceProductList",
        title: "Product List",
        link: "/ecommerce/product-list",
        icon: "Info",
      },
      {
        id: 5,
        target: "UserList",
        title: "Users",
        link: "/user/list",
        icon: "Eye",
      },
      {
        id: 6,
        target: "UserRegister",
        title: "Register",
        link: "/user/register",
        icon: "UserPlus",
      },
      {
        id: 7,
        target: "CustomerList",
        title: "Customers",
        link: "/ecommerce/customers",
        icon: "UserCheck",
      },
      {
        id: 8,
        target: "ShopList",
        title: "Shops",
        link: "/ecommerce/shops",
        icon: "Users",
      },
      {
        id: 8,
        target: "Ecommerce",
        title: "Ecommerce Dashboard",
        link: "/ecommerce",
        icon: "UserCheck",
      },
    ],
  },
];

mock.onGet("/api/main-search/data").reply(200, {
  searchResult,
});
