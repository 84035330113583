export const GET_CART_ORDERS = "GET_CART_ORDERS";
export const GET_CART_ORDERS_SUCCESS = "GET_CART_ORDERS_SUCCESS";
export const GET_CART_ORDERS_FAIL = "GET_CART_ORDERS_FAIL";

export const GET_CART_ORDERS_STATUS = "GET_CART_ORDERS_STATUS";
export const GET_CART_ORDERS_STATUS_SUCCESS = "GET_CART_ORDERS_STATUS_SUCCESS";
export const GET_CART_ORDERS_STATUS_FAIL = "GET_CART_ORDERS_STATUS_FAIL";

export const CREATE_CART_ORDER = "CREATE_CART_ORDER";
export const CREATE_CART_ORDER_SUCCESS = "CREATE_CART_ORDER_SUCCESS";
export const CREATE_CART_ORDER_FAIL = "CREATE_CART_ORDER_FAIL";

export const UPDATE_CART_ORDER_SUCCESS = "UPDATE_CART_ORDER_SUCCESS";
export const UPDATE_CART_ORDER_FAIL = "UPDATE_CART_ORDER_FAIL";

export const ORDER_CART_OVERVIEW_FAILED = "ORDER_CART_OVERVIEW_FAILED";
