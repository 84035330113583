import {
  IMAGE_UPLOAD,
  IMAGE_UPLOADED,
  UPLOAD_FAILED,
} from "../../actions/ecommerce/types";

const initialState = {
  imageData: {},
  loading: false,
};

const common = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case IMAGE_UPLOAD:
      return { ...state, loading: true };

    case IMAGE_UPLOADED:
      return {
        ...state,
        loading: false,
        imageData: payload,
      };
    case UPLOAD_FAILED:
      return { ...state, loading: false };
    default: {
      return state;
    }
  }
};

export default common;
