export const GET_OVERVIEW_DATA = "GET_OVERVIEW_DATA";
export const STAT_OFFERS_LAST14DAYS = "STAT_OFFERS_LAST14DAYS";
export const STAT_OFFERS_YESTERDAY = "STAT_OFFERS_YESTERDAY";
export const STAT_OFFERS_TODAY = "STAT_OFFERS_TODAY";
export const GET_TODAY_OFFERS_DATA = "GET_TODAY_OFFERS_DATA";
export const GET_TODAY_OFFERS_DATA_SUCCESS = "GET_TODAY_OFFERS_DATA_SUCCESS";
export const GET_WEEKLY_OFFERS_DATA = "GET_WEEKLY_OFFERS_DATA";
export const GET_WEEKLY_OFFERS_DATA_SUCCESS = "GET_WEEKLY_OFFERS_DATA_SUCCESS";
export const GET_DATA_FAIL = "GET_DATA_FAIL";
export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const CREATE_PRODUCT_FAIL = "CREATE_PRODUCT_FAIL";
export const EDIT_PRODUCT = "EDIT_PRODUCT";
export const EDIT_PRODUCT_SUCCESS = "EDIT_PRODUCT_SUCCESS";
export const EDIT_PRODUCT_FAIL = "EDIT_PRODUCT_FAIL";
export const GET_SHOPS = "GET_SHOPS";
export const CREATE_SHOP = "CREATE_SHOP";
export const CREATE_SHOP_ERROR = "CREATE_SHOP_ERROR";
export const GET_SHOPS_SUCCESS = "GET_SHOPS_SUCCESS";
export const GET_SHOP_DATA = "GET_SHOP_DATA";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const SHOP_PER_PAGE = 14;
export const PRODUCT_PER_PAGE = 14;
export const GET_SHOP_PRODUCTS = "GET_SHOP_PRODUCTS";
export const GET_SHOP_PRODUCTS_FAIL = "GET_SHOP_PRODUCTS_FAIL";
export const GET_SHOP_PRODUCTS_SUCCESS = "GET_SHOP_PRODUCTS_SUCCESS";
export const GET_SHOP_INITIAL_PRODUCTS_SUCCESS = "GET_SHOP_PRODUCTS_SUCCESS";

export const IMAGE_UPLOAD = "IMAGE_UPLOAD";
export const IMAGE_UPLOADED = "IMAGE_UPLOADED";
export const UPLOAD_FAILED = "UPLOAD_FAILED";

export const GET_SHOP_DETAILS = "GET_SHOP_DETAILS";
export const GET_SHOP_DETAILS_SUCCESS = "GET_SHOP_DETAILS_SUCCESS";
export const GET_SHOP_DETAIL_FAIL = "GET_SHOP_DETAIL_FAIL";

export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const GET_ALL_SPECIAL_PRODUCTS = "GET_ALL_SPECIAL_PRODUCTS";
export const GET_ALL_BUNDLE_PRODUCTS = "GET_ALL_BUNDLE_PRODUCTS";
export const CREATE_NEW_BUNDLE = "CREATE_NEW_BUNDLE";
export const CREATE_NEW_SPECIAL = "CREATE_NEW_SPECIAL";
export const CREATE_NEW_BUNDLE_SUCCESS = "CREATE_NEW_BUNDLE_SUCCESS";
export const CREATE_NEW_SPECIAL_SUCCESS = "CREATE_NEW_SPECIAL_SUCCESS";
export const GET_ALL_INITIAL_PRODUCTS = "GET_ALL_INITIAL_PRODUCTS";
export const GET_ALL_PRODUCTS_SUCCESS = "GET_ALL_PRODUCTS_SUCCESS";
export const GET_ALL_PRODUCTS_FAIL = "GET_ALL_PRODUCTS_FAIL";

export const GET_SINGLE_PRODUCT = "GET_SINGLE_PRODUCT";
export const GET_SINGLE_PRODUCT_SUCCESS = "GET_SINGLE_PRODUCT_SUCCESS";
export const GET_SINGLE_PRODUCT_FAIL = "GET_SINGLE_PRODUCT_FAIL";

export const GET_SHOP_OVERVIEW = "GET_SHOP_OVERVIEW";
export const GET_SHOP_OVERVIEW_SUCCESS = "GET_SHOP_OVERVIEW_SUCCESS";
export const GET_SHOP_OVERVIEW_FAIL = "GET_SHOP_OVERVIEW_FAIL";

export const UPDATE_PRODUCT_STATUS = "UPDATE_PRODUCT_STATUS";
export const UPDATE_PRODUCT_STATUS_SUCCESS = "UPDATE_PRODUCT_STATUS_SUCCESS";
export const UPDATE_PRODUCT_STATUS_FAIL = "UPDATE_PRODUCT_STATUS_FAIL";

export const APPROVE_MERCHANT_POST_REQUEST = "APPROVE_MERCHANT_POST_REQUEST";
export const APPROVE_MERCHANT_POST_REQUEST_SUCCESS =
  "APPROVE_MERCHANT_POST_REQUEST_SUCCESS";
export const APPROVE_MERCHANT_POST_REQUEST_FAIL =
  "APPROVE_MERCHANT_POST_REQUEST_FAIL";

export const GET_MERCHANTS_FOR_POST_APPROVAL =
  "GET_MERCHANTS_FOR_POST_APPROVAL";
export const GET_MERCHANTS_FOR_POST_APPROVAL_SUCCESS =
  "GET_MERCHANTS_FOR_POST_APPROVAL_SUCCESS";
export const GET_MERCHANTS_FOR_POST_APPROVAL_FAIL =
  "APPROVE_MERCHANT_POST_REQUEST_FAIL";

export const DENY_MERCHANTS_POST_REQUEST = "DENY_MERCHANTS_POST_REQUEST";
export const DENY_MERCHANTS_POST_REQUEST_SUCCESS =
  "DENY_MERCHANTS_POST_REQUEST_SUCCESS";
export const DENY_MERCHANTS_POST_REQUEST_FAIL =
  "DENY_MERCHANTS_POST_REQUEST_FAIL";

export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";
export const CREATE_CATEGORY_FAIL = "CREATE_CATEGORY_FAIL";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAIL = "UPDATE_CATEGORY_FAIL";
export const REMOVE_CATEGORY_SUCCESS = "REMOVE_CATEGORY_SUCCESS";

export const CREATE_SUBCATEGORY_SUCCESS = "CREATE_SUBCATEGORY_SUCCESS";
export const CREATE_SUBCATEGORY_FAIL = "CREATE_SUBCATEGORY_FAIL";
export const UPDATE_SUBCATEGORY_SUCCESS = "UPDATE_SUBCATEGORY_SUCCESS";
export const UPDATE_SUBCATEGORY_FAIL = "UPDATE_SUBCATEGORY_FAIL";
export const REMOVE_SUBCATEGORY_SUCCESS = "REMOVE_SUBCATEGORY_SUCCESS";
