export const GET_ORDERS = "GET_ORDERS";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_FAIL = "GET_ORDERS_FAIL";

export const GET_ORDERS_STATUS = "GET_ORDERS_STATUS";
export const GET_ORDERS_STATUS_SUCCESS = "GET_ORDERS_STATUS_SUCCESS";
export const GET_ORDERS_STATUS_FAIL = "GET_ORDERS_STATUS_FAIL";

export const CREATE_ORDER = "CREATE_ORDER";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const CREATE_ORDER_FAIL = "CREATE_ORDER_FAIL";

export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const UPDATE_ORDER_FAIL = "UPDATE_ORDER_FAIL";

export const ORDER_OVERVIEW_FAILED = "ORDER_OVERVIEW_FAILED";
