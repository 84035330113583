import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import setAuthToken from "../../utility/setAuthToken";
import rootReducer from "../reducers/rootReducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const initialState = {};

const middleware = [thunk];

const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middleware))
);

// set up a store subscription listener
// to store the users token in localStorage

// initialize current state from redux store for subscription comparison
// preventing undefined error
let currentState = store.getState();

store.subscribe(() => {
  // keep track of the previous and current state to compare changes
  let previousState = currentState;
  currentState = store.getState();
  // if the token changes set the value in localStorage and axios headers
  if (previousState.auth.login.token !== currentState.auth.login.token) {
    const token = currentState.auth.login.token;
    console.log("got here two", token);
    setAuthToken(token);
  }
});

export default store;
