import toast from "toasted-notes";
import "toasted-notes/src/styles.css";
import {
  GET_ALL_USERS,
  GET_ALL_USERS_FAIL,
  GET_ALL_USERS_SUCCESS,
  GET_MERCHANT_ORDERS_SUCCESS,
  GET_USERS_ORDERS_SUCCESS,
  LOAD_USER,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  REGISTER_USER,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  USER_LOAD_SUCCESS,
} from "../../actions/user/types";

const initialState = {
  userList: [],
  register: null,
  loading: false,
  allUsers: undefined,
  allUsersLoading: false,
  allUsersError: undefined,
  data: [],
  userOrders: [],
  merchantOrders: [],
  totalPages: 0,
  filteredData: [],
  totalRecords: 0,
};

const getIndex = (arr, arr2, arr3, params = {}) => {
  if (arr2.length > 0) {
    let startIndex = arr.findIndex((i) => i.id === arr2[0].id) + 1;
    let endIndex = arr.findIndex((i) => i.id === arr2[arr2.length - 1].id) + 1;
    let finalArr = [startIndex, endIndex];
    return (arr3 = finalArr);
  } else {
    let finalArr = [arr.length - parseInt(params.perPage), arr.length];
    return (arr3 = finalArr);
  }
};
export const users = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_USER:
      return {
        ...state,
        userList: payload,
        loading: true,
      };
    case USER_LOAD_SUCCESS:
      return {
        ...state,
        userList: payload,
        loading: false,
      };
    case REGISTER_USER:
      return {
        ...state,
        register: payload,
        loading: true,
      };
    case REGISTER_SUCCESS:
      toast.notify("New user created successfully!", {
        position: "bottom-right",
      });
      return { ...state, register: payload, loading: false, error: null };

    case REGISTER_FAIL:
      return { ...state, error: payload, loading: false };

    case UPDATE_USER:
      return {
        ...state,
        register: payload,
        loading: true,
      };
    case UPDATE_USER_SUCCESS:
      toast.notify("User updated successfully!", {
        position: "bottom-right",
      });
      return { ...state, register: payload, loading: false, error: null };

    case GET_ALL_USERS:
      return {
        ...state,
        allUsersLoading: true,
      };
    case GET_ALL_USERS_SUCCESS:
      console.log("my action", action.data);
      return {
        ...state,

        data: action.data,
        totalPages: Math.ceil(action.totalCount / action.data.length),
        totalRecords: action.totalCount,
        allUsersLoading: false,
        sortIndex: getIndex(
          action.data,
          action.data,
          state.sortIndex,
          action.params
        ),
      };
    case GET_ALL_USERS_FAIL:
      return {
        ...state,
        allUsersLoading: false,
        allUsersError: payload,
      };
    case GET_USERS_ORDERS_SUCCESS:
      return {
        ...state,

        userOrders: action.data,
        totalPages: Math.ceil(action.totalCount / action.data.length),
        totalRecords: action.totalCount,
        allUsersLoading: false,
        sortIndex: getIndex(
          action.data,
          action.data,
          state.sortIndex,
          action.params
        ),
      };
    case GET_MERCHANT_ORDERS_SUCCESS:
      return {
        ...state,

        merchantOrders: action.data,
        totalPages: Math.ceil(action.totalCount / action.data.length),
        totalRecords: action.totalCount,
        allUsersLoading: false,
        sortIndex: getIndex(
          action.data,
          action.data,
          state.sortIndex,
          action.params
        ),
      };

    default: {
      return state;
    }
  }
};
