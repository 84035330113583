import {
  GET_SHOPS_SUCCESS,
  GET_SHOP_DATA,
} from "../../actions/ecommerce/types";

const initialState = {
  data: [],
  params: null,
  allData: [],
  totalPages: 0,
  filteredData: [],
  totalRecords: 0,
  loading: false,
  sortIndex: [],
  next: "",
  previous: "",
};

const DataListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SHOP_DATA:
      console.log("action.data", action.data);
      return {
        ...state,
        data: action.data,
        allData: action.data.results,
        totalPages: action.totalPages,
        params: action.params,
        next: action.next,
        previous: action.previous,
      };
    case GET_SHOPS_SUCCESS: //initial data
      return {
        ...state,
        loading: false,
        allData: action.data.results,
        data: action.data.results,

        totalPages: action.totalPages,
        next: action.data.next,
        previous: action.data.previous,
      };
    case "FILTER_DATA":
      let value = action.value;
      let filteredData = [];
      if (value.length) {
        filteredData = state.allData
          .filter((item) => {
            let startsWithCondition =
              item.name.toLowerCase().startsWith(value.toLowerCase()) ||
              item.category.toLowerCase().startsWith(value.toLowerCase()) ||
              item.price.toLowerCase().startsWith(value.toLowerCase()) ||
              item.order_status.toLowerCase().startsWith(value.toLowerCase());

            let includesCondition =
              item.name.toLowerCase().includes(value.toLowerCase()) ||
              item.category.toLowerCase().includes(value.toLowerCase()) ||
              item.price.toLowerCase().includes(value.toLowerCase()) ||
              item.order_status.toLowerCase().includes(value.toLowerCase());

            if (startsWithCondition) {
              return startsWithCondition;
            } else if (!startsWithCondition && includesCondition) {
              return includesCondition;
            } else return null;
          })
          .slice(state.params.page - 1, state.params.perPage);
        return { ...state, filteredData };
      } else {
        filteredData = state.data;
        return { ...state, filteredData };
      }

    case "UPDATE_DATA":
      state.data.find((item) => {
        if (item.id === action.obj.id) {
          return Object.assign(item, { ...action.obj });
        } else {
          return item;
        }
      });
      return { ...state };
    case "DELETE_DATA":
      let index = state.data.findIndex((item) => item.id === action.obj.id);
      let updatedData = [...state.data];
      updatedData.splice(index, 1);
      return {
        ...state,
        data: updatedData,
        totalRecords: state.allData.length,
      };
    default:
      return state;
  }
};

export default DataListReducer;
