import {
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGOUT,
  REGISTER_ADMIN_FAIL,
  REGISTER_ADMIN_SUCCESS,
  UPDATE_ADMIN_FAIL,
  UPDATE_ADMIN_SUCCESS,
  USER_LOADED,
} from "../../actions/auth/types";
import toast from "toasted-notes";
import "toasted-notes/src/styles.css"; // optional styles
import { history } from "../../../history";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  loading: true,
  role: null,
  user: null,
};
export const login = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        token: payload.token,
        isAuthenticated: true,
        loading: false,
      };
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload,
        role: payload.role,
      };

    case AUTH_ERROR:
    case LOGOUT:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null,
      };
    case REGISTER_ADMIN_SUCCESS:
      return {
        ...state,
        token: payload.token,
        isAuthenticated: true,
        loading: false,
      };
    case REGISTER_ADMIN_FAIL:
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
      };
    case UPDATE_ADMIN_SUCCESS:
      toast.notify("Admin data updated successfully!", {
        position: "bottom-right",
        duration: 3000,
        type: "success",
      });
      history.goBack();
      return {
        ...state,
      };
    case UPDATE_ADMIN_FAIL:
      toast.notify(`Admin data update failed!, ${payload}`, {
        position: "bottom-right",
        duration: 3000,
        type: "error",
      });
      return {
        ...state,
      };
    default: {
      return state;
    }
  }
};
