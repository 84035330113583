import React from "react";
import * as Icon from "react-feather";

const horizontalMenuConfig = [
  {
    id: "home",
    title: "Home",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/",
  },
  {
    id: "eCommerce",
    title: "Ecommerce",
    type: "dropdown",
    icon: <Icon.ShoppingCart size={16} />,
    children: [
      {
        id: "ecommerce",
        title: "Ecommerce",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/ecommerce/dashboard",
        permissions: ["admin", "editor"],
      },
      {
        id: "promotion",
        title: "Promotion",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/ecommerce/promotions",
        permissions: ["admin", "editor"],
      },
      {
        id: "customers",
        title: "Customers",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/ecommerce/customers",
        permissions: ["admin", "editor"],
      },
      {
        id: "shops",
        title: "shops",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/ecommerce/shops",
        permissions: ["admin", "editor"],
      },
    ],
  },
  {
    id: "users",
    title: "User",
    type: "collapse",
    icon: <Icon.User size={20} />,
    children: [
      {
        id: "list",
        title: "List",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/user/list",
      },

      {
        id: "register",
        title: "Register",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/user/register",
      },
    ],
  },
  {
    id: "page2",
    title: "Page 2",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/page2",
  },
];

export default horizontalMenuConfig;
