/* eslint-disable array-callback-return */
import { GET_PRODUCT_FORM } from "../../actions/forms/types";

const initialState = {
  loading: true,
  config: {},
  createFieldsets: {},
  product_types: {},
  filterFields: {},
  detailFields: {},
};

const getProductTypes = (rawJson) => {
  let typesArray = {};
  rawJson.product_types.map(
    (type) => (typesArray[type.key] = type.display_name)
  );
  return typesArray;
};

const createFildsets = (rawJson) => {
  let createSections = {};
  rawJson.sections.filter((item) => {
    if (item.section_id.endsWith("-create")) {
      return (createSections[item.section_id] = item.fieldsets);
    }
  });

  return createSections;
};

const filterFilterFields = (rawJson) => {
  let filterSection = {};
  rawJson.sections.filter((item) => {
    if (item.section_id.endsWith("-filter")) {
      filterSection[item.section_id] = item.fieldsets;
    }
  });

  return filterSection;
};

const detailFildsets = (rawJson) => {
  let detailSections = {};
  rawJson.sections.filter((item) => {
    if (item.section_id.endsWith("-detail")) {
      detailSections[item.section_id] = item.fieldsets;
    }
  });

  return detailSections;
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PRODUCT_FORM:
      return {
        ...state,
        product_types: getProductTypes(payload),
        createFieldsets: createFildsets(payload),
        filterFields: filterFilterFields(payload),
        detailFieldsets: detailFildsets(payload),
        loading: false,
      };

    default:
      return state;
  }
}
