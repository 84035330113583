import mock from "../mock";

export const searchResult = [
  {
    id: 1,
    target: "dashboard",
    title: "Dashboard",
    link: "/",
    icon: "Home",
    starred: false,
  },

  {
    id: 2,
    target: "page",
    title: "Page 2",
    link: "/page2",
    icon: "File",
    starred: false,
  },

  {
    id: 3,
    target: "E-commerceShops",
    title: "Shops",
    link: "/ecommerce/shops",
    icon: "Info",
    starred: false,
  },
  {
    id: 4,
    target: "E-commerceProductList",
    title: "Product List",
    link: "/ecommerce/product-list",
    icon: "Info",
    starred: false,
  },

  {
    id: 5,
    target: "UserList",
    title: "Users",
    link: "/user/list",
    icon: "Eye",
    starred: false,
  },
  {
    id: 6,
    target: "UserRegister",
    title: "Register",
    link: "/user/register",
    icon: "UserPlus",
    starred: false,
  },
  {
    id: 7,
    target: "CustomerList",
    title: "Customers",
    link: "/ecommerce/customers",
    icon: "UserCheck",
    starred: false,
  },
  {
    id: 8,
    target: "MerchantList",
    title: "Merchants",
    link: "/ecommerce/merchants",
    icon: "Users",
    starred: false,
  },
];

mock.onGet("/api/search/bookmarks/data").reply(200, {
  searchResult,
});

mock.onPost("/api/update/bookmarks").reply((request) => {
  const bookmarkToUpdate = JSON.parse(request.data).obj;

  searchResult.filter((i) => {
    if (i.id === bookmarkToUpdate.id) {
      return (i.starred = !bookmarkToUpdate.starred);
    } else {
      return null;
    }
  });
  return [200];
});
