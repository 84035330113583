import toaster from "toasted-notes";
import "toasted-notes/src/styles.css"; // optional styles
import {
  CREATE_PRODUCT,
  CREATE_PRODUCT_FAIL,
  CREATE_PRODUCT_SUCCESS,
  GET_ALL_PRODUCTS_FAIL,
  GET_DATA_FAIL,
  GET_OVERVIEW_DATA,
  GET_TODAY_OFFERS_DATA,
  GET_TODAY_OFFERS_DATA_SUCCESS,
  GET_WEEKLY_OFFERS_DATA,
  GET_WEEKLY_OFFERS_DATA_SUCCESS,
  STAT_OFFERS_LAST14DAYS,
  STAT_OFFERS_TODAY,
  STAT_OFFERS_YESTERDAY,
} from "../../actions/ecommerce/types";

const initialState = {
  offersLast14Days: [],
  offersYesterday: [],
  offersToady: [],
  todayPendingOffersData: 0,
  todayFinishedOffersData: 0,
  todayRejectedOffersData: 0,
  weeklyOffersData: 0,
  products: [],
  loading: false,
  newProduct: null,
  imageUri: null,
  data: [],
  params: null,
  allData: [],
  totalPages: 0,
  filteredData: [],
  totalRecords: 0,

  sortIndex: [],
};

const ecommerce = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_OVERVIEW_DATA:
      return {
        ...state,
        loading: true,
      };
    case STAT_OFFERS_LAST14DAYS:
      return {
        ...state,
        offersLast14Days: payload,
        loading: false,
      };
    case STAT_OFFERS_YESTERDAY:
      return {
        ...state,
        offersYesterday: payload,
        loading: false,
      };
    case STAT_OFFERS_TODAY:
      return {
        ...state,
        offersToady: payload,
        loading: false,
      };
    case GET_TODAY_OFFERS_DATA:
      return {
        ...state,
        loading: true,
      };
    case GET_TODAY_OFFERS_DATA_SUCCESS:
      return {
        ...state,
        todayPendingOffersData: payload,

        loading: false,
      };
    case GET_WEEKLY_OFFERS_DATA:
      return {
        ...state,
        loading: true,
      };
    case GET_WEEKLY_OFFERS_DATA_SUCCESS:
      return {
        ...state,
        weeklyOffersData: payload,
        loading: false,
      };
    case GET_DATA_FAIL:
      return { ...state, loading: false };

    case CREATE_PRODUCT:
      return {
        ...state,
        loading: true,
      };
    case CREATE_PRODUCT_SUCCESS:
      toaster.notify("New product created", {
        position: "bottom-right",
        duration: null,
      });
      return { ...state, newProduct: payload, loading: false };

    case CREATE_PRODUCT_FAIL:
      return { ...state, loading: false };

    /* case GET_PRODUCTS:
      return {
        ...state,
        loading: true,
      };
    case GET_PRODUCTS_SUCCESS:
      return { ...state, products: payload, loading: false };

    case GET_ALL_PRODUCTS:
      return {
        ...state,
        loading: true,
      };
     case GET_ALL_PRODUCTS_SUCCESS:
       console.log("action.data", action.data);
      return {
        ...state,
        data: action.data,
        totalPages: action.totalPages,
        params: action.params,
        next: action.next,
        previous: action.previous,
        sortIndex: getIndex(
          state.allData,
          action.data,
          state.sortIndex,
          action.params
        ),
      }; */
    /*     case GET_ALL_INITIAL_PRODUCTS: //initial data
      console.log(
        "size",
        Math.ceil(action.data.count / action.data.results.length)
      );
      return {
        ...state,
        loading: false,
        allData: action.data.results,
        totalPages: action.totalPages,
        next: action.data.next,
        previous: action.data.previous,
        sortIndex: getIndex(action.data, state.data, state.sortIndex),
      }; */
    case GET_ALL_PRODUCTS_FAIL:
      return { ...state, loading: false, failed: true };

    default: {
      return state;
    }
  }
};

export default ecommerce;
