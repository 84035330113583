export const LOAD_USER = "LOAD_USER";
export const USER_LOAD_SUCCESS = "USER_LOAD_SUCCESS";
export const USER_LOAD_FAIL = "USER_LOAD_FAIL";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAILED";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_USERS_ORDERS_SUCCESS = "GET_USERS_ORDERS_SUCCESS";
export const GET_MERCHANT_ORDERS_SUCCESS = "GET_MERCHANT_ORDERS_SUCCESS";
export const GET_ALL_USERS_FAIL = "GET_ALL_USERS_FAIL";
