import { combineReducers } from "redux";
import alert from "./alert";
import auth from "./auth/";
import common from "./common";
import customizer from "./customizer/";
import dataList from "./data-list/";
import ecommerce from "./ecommerce/ecommerceReducer";
import product from "./ecommerce/productReducer";
import shop from "./ecommerce/shopsReducer";
import form from "./form";
import navbar from "./navbar/Index";
import order from "./order";
import cart from "./cart";
import user from "./user";

const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  dataList: dataList,
  alert: alert,
  form: form,
  user: user,
  ecommerce: ecommerce,
  common: common,
  shop: shop,
  product: product,
  orders: order,
  carts: cart,
});

export default rootReducer;
