import React from "react";
import * as Icon from "react-feather";
const navigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/",
  },

  {
    type: "groupHeader",
    groupTitle: "Ecommerce",
  },
  {
    id: "post_request",
    title: "Post Requests",
    type: "item",
    icon: <Icon.Circle size={10} />,
    navLink: "/ecommerce/requests",
    permissions: ["admin", "editor"],
  },
  {
    id: "orders",
    title: "Orders",
    type: "item",
    icon: <Icon.Circle size={10} />,
    navLink: "/ecommerce/orders",
    permissions: ["admin", "editor", "staff"],
  },
  {
    id: "cart",
    title: "Cart Orders",
    type: "item",
    icon: <Icon.Circle size={10} />,
    navLink: "/ecommerce/cart",
    permissions: ["admin", "editor"],
  },
  {
    id: "categories",
    title: "Categories",
    type: "item",
    icon: <Icon.Circle size={10} />,
    navLink: "/ecommerce/categories",
    permissions: ["admin", "editor", "staff"],
  },
  {
    id: "subcategories",
    title: "Subcategories",
    type: "item",
    icon: <Icon.Circle size={10} />,
    navLink: "/ecommerce/subcategories",
    permissions: ["admin", "editor", "staff"],
  },
  {
    id: "promotion",
    title: "Promotions",
    type: "item",
    icon: <Icon.Circle size={10} />,
    navLink: "/ecommerce/promotions",
    permissions: ["admin", "editor"],
  },

  {
    type: "groupHeader",
    groupTitle: "Customers",
  },
  {
    id: "customers",
    title: "Customers",
    type: "item",
    icon: <Icon.Circle size={10} />,
    navLink: "/ecommerce/customers",
    permissions: ["admin", "editor", "staff"],
  },
  {
    id: "shops",
    title: "Merchant",
    type: "item",
    icon: <Icon.Circle size={10} />,
    navLink: "/ecommerce/merchants",
    permissions: ["admin", "editor", "staff"],
  },
  {
    id: "merchant_requests",
    title: "Merchant Requests",
    type: "item",
    icon: <Icon.Circle size={10} />,
    navLink: "/ecommerce/merchant/requests",
    permissions: ["admin", "editor", "staff"],
  },
  {
    id: "account_delete_requests",
    title: "Delete Requests",
    type: "item",
    icon: <Icon.Circle size={10} />,
    navLink: "/ecommerce/merchant/delete_requests",
    permissions: ["admin", "editor", "staff"],
  },
  {
    id: "support",
    title: "Support",
    type: "item",
    icon: <Icon.Circle size={10} />,
    navLink: "/support",
    permissions: ["admin", "editor", "staff"],
  },
  {
    type: "groupHeader",
    groupTitle: "Administrator",
  },

  {
    id: "users",
    title: "User",
    type: "collapse",
    icon: <Icon.User size={20} />,
    children: [
      {
        id: "list",
        title: "List",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/user/list",
      },
      {
        id: "register",
        title: "Register",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/user/register",
      },
    ],
  },
];

export default navigationConfig;
