import toast from "toasted-notes";
import "toasted-notes/src/styles.css"; // optional styles
import {
  GET_ALL_BUNDLE_PRODUCTS,
  CREATE_NEW_BUNDLE,
  CREATE_NEW_BUNDLE_SUCCESS,
  GET_ALL_INITIAL_PRODUCTS,
  GET_ALL_PRODUCTS,
  GET_ALL_PRODUCTS_FAIL,
  GET_ALL_PRODUCTS_SUCCESS,
  GET_ALL_SPECIAL_PRODUCTS,
  GET_SINGLE_PRODUCT,
  GET_SINGLE_PRODUCT_FAIL,
  GET_SINGLE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_STATUS,
  UPDATE_PRODUCT_STATUS_FAIL,
  UPDATE_PRODUCT_STATUS_SUCCESS,
  IMAGE_UPLOAD,
  IMAGE_UPLOADED,
  CREATE_NEW_SPECIAL,
  CREATE_NEW_SPECIAL_SUCCESS,
  CREATE_PRODUCT,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_FAIL,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_FAIL,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAIL,
  REMOVE_CATEGORY_SUCCESS,
  CREATE_SUBCATEGORY_SUCCESS,
  CREATE_SUBCATEGORY_FAIL,
  UPDATE_SUBCATEGORY_SUCCESS,
  UPDATE_SUBCATEGORY_FAIL,
  REMOVE_SUBCATEGORY_SUCCESS,
} from "../../actions/ecommerce/types";

const initialState = {
  data: [],
  newProduct: {},
  params: null,
  allData: [],
  totalPages: 0,
  filteredData: [],
  totalRecords: 0,
  product_loading: false,
  status_loading: false,
  sortIndex: [],
  updatedProduct: null,
  productDetail: {},
};

const getIndex = (arr, arr2, arr3, params = {}) => {
  if (arr2.length > 0) {
    let startIndex = arr.findIndex((i) => i.id === arr2[0].id) + 1;
    let endIndex = arr.findIndex((i) => i.id === arr2[arr2.length - 1].id) + 1;
    let finalArr = [startIndex, endIndex];
    return (arr3 = finalArr);
  } else {
    let finalArr = [arr.length - parseInt(params.perPage), arr.length];
    return (arr3 = finalArr);
  }
};

const product = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_PRODUCTS:
      return {
        ...state,
        product_loading: true,
      };
    case GET_ALL_BUNDLE_PRODUCTS:
      return {
        ...state,
        product_loading: true,
      };
    case CREATE_NEW_BUNDLE:
      return {
        ...state,
        status_loading: true,
      };
    case CREATE_NEW_BUNDLE_SUCCESS:
      return {
        ...state,
        data: action.data,
        status_loading: false,
        sortIndex: getIndex(
          action.data,
          action.data,
          state.sortIndex,
          action.params
        ),
      };
    case CREATE_NEW_SPECIAL:
      return {
        ...state,
        status_loading: true,
      };
    case CREATE_NEW_SPECIAL_SUCCESS:
      toast.notify("Special Offer successfully created!", {
        position: "bottom-right",
        duration: 3000,
      });
      return {
        ...state,
        status_loading: false,
      };
    case CREATE_PRODUCT:
      return {
        ...state,
        status_loading: true,
      };
    case CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        newProduct: action.data,
        status_loading: false,
      };
    case IMAGE_UPLOAD:
      return {
        ...state,
        status_loading: true,
      };
    case IMAGE_UPLOADED:
      return {
        ...state,
        status_loading: false,
        data: action.data,
      };
    case GET_ALL_SPECIAL_PRODUCTS:
      return {
        ...state,
        product_loading: true,
      };
    case GET_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        data: action.data,
        totalPages: Math.ceil(action.totalCount / 10),
        product_loading: false,
        updatedProduct: null,
        sortIndex: getIndex(
          action.data,
          action.data,
          state.sortIndex,
          action.params
        ),
      };
    case GET_ALL_INITIAL_PRODUCTS: //initial data
      console.log(
        "size"
        // Math.ceil(action.data.count / action.data.results.length)
      );
      return {
        ...state,
        loading: false,
        allData: action.data.results,
        totalPages: action.totalPages,
        next: action.data.next,
        previous: action.data.previous,
        sortIndex: getIndex(action.data, state.data, state.sortIndex),
      };
    case GET_ALL_PRODUCTS_FAIL:
      return { ...state, loading: false, failed: true };

    case GET_SINGLE_PRODUCT:
      return { ...state, loading: true };
    case GET_SINGLE_PRODUCT_SUCCESS:
      return { ...state, loading: false, productDetail: action.payload };
    case GET_SINGLE_PRODUCT_FAIL:
      return { ...state, loading: false };
    case UPDATE_PRODUCT_STATUS:
      return { ...state, status_loading: true };
    case UPDATE_PRODUCT_STATUS_SUCCESS:
      toast.notify("Product status updated!", {
        position: "bottom-right",
        duration: 1000,
      });

      return {
        ...state,
        status_loading: false,
        updatedProduct: payload,
      };
    case UPDATE_PRODUCT_STATUS_FAIL:
      return { ...state, status_loading: false };

    case CREATE_CATEGORY_SUCCESS:
      toast.notify("Category created successfully!", {
        position: "bottom-right",
        duration: 3000,
        type: "success",
      });
      return { ...state, loading: false };

    case CREATE_CATEGORY_FAIL:
      toast.notify(`Category Create Failed!, ${action.payload}`, {
        position: "bottom-right",
        duration: 3000,
        type: "error",
      });
      return { ...state, loading: false };

    case UPDATE_CATEGORY_SUCCESS:
      toast.notify("Category updated successfully!", {
        position: "bottom-right",
        duration: 3000,
        type: "success",
      });
      return { ...state, loading: false };

    case UPDATE_CATEGORY_FAIL:
      toast.notify(`Category Update failed!, ${action.payload}`, {
        position: "bottom-right",
        duration: 3000,
        type: "error",
      });
      return { ...state, loading: false };

    case REMOVE_CATEGORY_SUCCESS:
      toast.notify("Category Removed successfully!", {
        position: "bottom-right",
        duration: 3000,
        type: "success",
      });
      return { ...state, loading: false };

    case CREATE_SUBCATEGORY_SUCCESS:
      toast.notify("Subategory Created successfully!", {
        position: "bottom-right",
        duration: 3000,
        type: "success",
      });
      return { ...state, loading: false };

    case CREATE_SUBCATEGORY_FAIL:
      toast.notify("Subategory Create Failed!", {
        position: "bottom-right",
        duration: 3000,
        type: "error",
      });
      return { ...state, loading: false };

    case UPDATE_SUBCATEGORY_SUCCESS:
      toast.notify("Subategory Updated Successfully!", {
        position: "bottom-right",
        duration: 3000,
        type: "success",
      });
      return { ...state, loading: false };

    case UPDATE_SUBCATEGORY_FAIL:
      toast.notify("Subategory Update Failed!", {
        position: "bottom-right",
        duration: 3000,
        type: "error",
      });
      return { ...state, loading: false };

    case REMOVE_SUBCATEGORY_SUCCESS:
      toast.notify("Subategory Removed Successfully!", {
        position: "bottom-right",
        duration: 3000,
        type: "success",
      });
      return { ...state, loading: false };

    default: {
      return state;
    }
  }
};

export default product;
